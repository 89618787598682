<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <div class="card">
        <Toast />
        <DataTable
          ref="dt"
          :value="visitas"
          class="p-datatable-sm p-datatable-striped"
          v-model:selection="selectedVisitas"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} visitantes"
        >
          <template #empty>
            <div class="p-text-center">Nenhum resultado encontrado...</div>
          </template>
          <template #header>
            <div class="table-header">
              <h5 class="p-m-0">Saída de Visitantes</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global']"
                  placeholder="Pesquisar..."
                />
              </span>
            </div>
          </template>
          <Column header="Foto">
            <template #body="slotProps">
              <img
                :src="slotProps.data.visitante.url"
                class="visita-image img-fluid"
              />
            </template>
          </Column>
          <Column field="cracha" header="Cracha" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Cracha</span>
              {{ slotProps.data.cracha.referencia }}
            </template>
          </Column>
          <Column field="nome" header="Nome" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Nome</span>
              {{ slotProps.data.visitante.nome }}
            </template>
          </Column>
          <Column field="destino" header="Destino" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Destino</span>
              {{ slotProps.data.destino }}
            </template>
          </Column>
          <Column field="motivo" header="Motivo" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Motivo</span>
              {{ slotProps.data.motivo }}
            </template>
          </Column>
          <Column field="dt_entrada" header="Entrada" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Entrada</span>
              {{ formatarDatas(slotProps.data.dt_entrada) }}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-sign-out"
                class="p-button-rounded p-button-danger"
                @click="confirSaidaVisita(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
        <Dialog
          v-model:visible="exitVisitaDialog"
          :style="{ width: '450px' }"
          header="Mensagem de Confirmação!"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="visita"
              >Tem certeza de que esse visitante já saiu do prédio?</span
            >
          </div>
          <template #footer>
            <Button
              label="Sim"
              icon="pi pi-check"
              class="p-button-raised"
              @click="exitVisita"
            />
            <Button
              label="Não"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="exitVisitaDialog = false"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="exitVisitasDialog"
          :style="{ width: '450px' }"
          header="Mensagem de Confirmação!"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="visita"
              >Tem certeza de que deseja excluir os registros selecionados
              ?</span
            >
          </div>
          <template #footer>
            <Button
              label="Sim"
              icon="pi pi-check"
              class="p-button-raised"
              @click="deleteSelectedVisitas"
            />
            <Button
              label="Não"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="exitVisitasDialog = false"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from "moment";
import AuthService from "@/service/Auth/AuthService";
import VisitaService from "@/service/Visita/VisitaService";
import VisitanteService from "@/service/Visitante/VisitanteService";

export default {
  data() {
    return {
      visitas: null,
      visitaDialog: false,
      exitVisitaDialog: false,
      exitVisitasDialog: false,
      nomesVisitantes: null,
      selectedNomesVisitantes: null,
      visita: {
        id: null,
        visitante: {
          id: null,
          foto: {
            id: null,
            base64: null,
          },
          nome: null,
          rg: null,
          cpf: null,
          telefone: null,
          origem: null,
        },
        cracha: {
          id: null,
          referencia: null,
          andar: null,
        },
        destino: null,
        motivo: null,
        dt_entrada: null,
        dt_saida: null,
      },
      selectedVisitas: null,
      filters: {},
      submitted: false,
      titleDialog: null,
      msg: null,
      msgUsuario: null,
      msgDesenvolvedor: null,
    };
  },
  visitaService: null,
  visitanteService: null,
  authService: null,
  created() {
    this.authService = new AuthService();
    this.visitaService = new VisitaService();
    this.visitanteService = new VisitanteService();
  },
  mounted() {
    this.authService.checarToken(this.listarVisitas());
  },
  methods: {
    listarVisitas() {
      this.visitaService.listarOndeSaidaNull().then((data) => {
        this.visitas = data;
        //console.log(this.visitas);
      });
    },
    formatarDatas(date) {
      if (date) {
        return Moment(date).format("DD/MM/YYYY h:mm:ss a");
      }
      return;
    },
    confirSaidaVisita(visita) {
      this.visita = visita;
      //console.log(this.visita);
      this.exitVisitaDialog = true;
    },
    exitVisita() {
      this.authService.checarToken(this.sair());
    },
    sair() {
      this.visitaService.sair(this.visita.id).then((data) => {
        if (data.status === 204) {
          this.exitVisitaDialog = false;
          this.visitante = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso!",
            detail: "Visita encerrada",
            life: 3000,
          });
          this.listarVisitas();
        } else {
          console.log(data);
          this.$toast.add({
            severity: "erro",
            summary: "Erro!",
            detail: "Erro ao encerrar a visita.",
            life: 3000,
          });
        }
      });
    },
    confirmDeleteSelected() {
      this.exitVisitasDialog = true;
    },
  },
};
</script>

<style scoped lang="scss">
.img-fluid {
  max-width: 100%;
  height: auto;
}
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.visitante-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.p-dialog .visitante-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.visitante-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }
  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }
  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }
}
.p-datatable-sm .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}
.visita-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-sm {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }
      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }
        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: right;
        }
        .p-rating {
          display: inline-block;
        }
      }
    }
  }
}
</style>